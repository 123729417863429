/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import {Link} from "gatsby"

const Layout = ({children, myfunc}) => {

  

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      },
    allFile(filter: {extension: {in: ["vsh","fsh", "pvr","nav", "pod", "pfx"]}}) {
    edges {
    node {
    publicURL
    id
    }
    }
    }
    }
  `)

  return (
    <><>{ ( myfunc !== null) ? myfunc(data) : null }</>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `auto auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <main >{children}</main>
        {/* <Link to="/">Home</Link> */}
        <a href="/index.html">Home</a>
        <footer bottom = "true">
       @2019  Just Math 24 Game 
        </footer>
      </div>
    </>
  )
}


Layout.defaultProps = {
  myfunc: null,
  children: {}
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  myfunc: PropTypes.func.isRequired,
}

export default Layout

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import RestoreIcon from '@material-ui/icons/Restore';
import React from "react";
import LavaLamp from "../components/lavalamp/LavaLamp";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 5000,
    position: "sticky",
    bottom: 0
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  content: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const CodePage = () => {


   var classes = useStyles()
   var [value, setValue] = React.useState(0)

  return (
  <>
 <LavaLamp pos = "3"></LavaLamp>

  <Layout>
  <CssBaseline />
    <SEO title="Page two" />
    <h4>Just math 24 is a Math Game created with swift</h4>
    <div style={{minHeight : "1000px"}}>
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Data Structrue</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <Paper className={classes.root}>
    
    <Typography>{ "  struct Data    {"} </Typography>
    <Typography>{ "          let value : Int" }</Typography>
    <Typography>{ "    let description: String "}</Typography>
    <Typography>{ "        static func + (a : Data, b: Data)-> Data\{   "}</Typography>
    <Typography>{ "            return  Data(value: a.value+b.value,     "}</Typography>
    <Typography>{ "                         description: \"( \(a.description) + \(b.description) )\")} "}</Typography>
    <Typography>{ "        static func - (a : Data, b: Data)-> Data{  "}</Typography>
    <Typography>{ "            return  Data(value: a.value-b.value,     "}</Typography>
    <Typography>{ "                        description: \"( \(a.description) - \(b.description) )\")}  "}</Typography>
    <Typography>{ "        static func * (a : Data, b: Data)-> Data{   "}</Typography>
    <Typography>{ "            return  Data(value: a.value*b.value,     "}</Typography>
    <Typography>{ "                         description: \"( \(a.description) * \(b.description) )\")}   "}</Typography>
    <Typography>{ "        static func / (a : Data, b: Data)-> Data{    "}</Typography>
    <Typography>{ "            return  Data(value: a.value/b.value,     "}</Typography>
    <Typography>{ "                         description: \"( \(a.description) / \(b.description) )\")}}  "}</Typography>
    <Typography>{ " "}</Typography>
    </Paper>
        
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Subroutinue</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <Typography>
          <p>{ " func additive(_ a: [[Data]],_  b : Data) -> [[[Data]]] {"}</p>
          <p>{ "  return  a.compactMap{ stride(from: ($0.count)-1, through: 0, by: -1).reduce( [[$0 + [b]]], {"}</p>
    <p>{ "  let index = $1"}</p>
    <p>{ "     return  $0 + $0.last!.compactMap{"}</p>
    <p>{ "      let array = $0"}</p>
    <p>{ "      let aa = array[index + 1]"}</p>
    <p>{ "      let bb = array[index]"}</p>
    <p>{ "      return  (aa.value == 0 ? [(bb+aa), (bb-aa) , (bb*aa)]"}</p>
    <p>{ "              .map{[Data](array.dropLast(2)) + [$0]}"}</p>
    <p>{ "                             : [(bb+aa), (bb-aa) , (bb*aa) , (bb/aa)]"}</p>
    <p>{ "              .map{ [Data](array.dropLast(2)) + [$0] })}}).flatMap{$0}}}  "}</p>
    <p>{ "              "}</p>
    </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Main Function</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <Typography>
        <p>{ "   func Calculate24(_ a: [Int]) -> String { "}</p>
    <p>{ "    let a = a.map{Data(value: $0, description: \"\($0)\")}"}</p>
    <p>{ "     return a.dropFirst().reduce([[a.first!]],  {"}</p>
    <p>{ "           additive($0, $1).flatMap{$0}"}</p>
    <p>{ "     }).filter{$0.count == 1 && $0[0].value == 24}"}</p>
    <p>{ "      .map{$0[0].description}.joined(separator: \"\n\")"}</p>
    <p>{ "     }"}</p>
          </Typography></ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
</div>
  </Layout>
  <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);

      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
      <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
      <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
    </BottomNavigation> </>
  )
}

export default CodePage



 
